<template>
  <admin-base ref="base" style="margin:40px">
    <div slot="title">
      Não autorizado
    </div>
    <div class="card">
      <div class="card-body text-center">
        <div class="col-12">
          Acesso não autorizado
        </div>
        <div class="col-12 mt-3">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="$router.push('/logout')">
            Ir para a Homepage
          </b-button>
        </div>
      </div>
    </div>
  </admin-base>
</template>

<script>
import * as bootstrapVue from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AdminBase from '@/views/EmptyBase.vue'

export default {
  components: {
    AdminBase,
    BButton: bootstrapVue.BButton,
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.$refs.base.hideLoading()
  },
}
</script>
